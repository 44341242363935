<template>
    <div class="view-container" v-if="referenceReport">
        <div class="bg-color-green">
            <section class="references-report-header">
                <div>
                    <span>Результаты по эталонному ЭИ-профилю</span>
                    <h3>Специалист по работе с клиентами в организации "<span>{{ companyName }}</span>"</h3>
                </div>
                <button @click="print" class="btn  btn-bg-white no-mobile">Распечатать</button>
            </section>
            <section class="parameters">
                <div class="parameters-title">Лучших результатов добиваются сотрудники<br>
                    с данными параметрами ЭИ:
                </div>
                <custom-checkbox name="format3" label="Показать неважные параметры" v-model="structure" class="checkbox"
                                 :checked="structure"/>
                <ul v-if="structure" class="parameters-cards">
                    <parameters-cards v-if="!isPreloader" :items="cards.values()" :structure="structure"/>
                </ul>
                <ul v-else-if="!structure" class="parameters-cards-no-structure">
                    <parameters-cards v-if="!isPreloader" :items="cards.values()"/>
                </ul>
            </section>
        </div>
        <div class="bg-color-white">
            <section class="detailed-report">
                <collapse-button :title="`Подробный отчет:`" @click="isOpenDetailed = !isOpenDetailed" style="display: none;"/>
                <Collapse :when="isOpenDetailed" class="collapse">
                    <ul class="detailed-report-cards">
                        <DetailedReportCards :items="detailedBasicItems"/>
                    </ul>
                    <h2>Детализация по ветвям:</h2>
                    <ul class="detailed-report-branches-cards">
                        <DetailedReportCards :items="detailedBranchesItems"/>
                    </ul>
                    <h2>Детализация по разделам:</h2>
                    <ul class="detailed-report-subbranch-cards">
                        <DetailedReportCards :items="detailedSubbranchItems"/>
                    </ul>
                    <button @click="print" class="btn ">Распечатать</button>
                </Collapse>
            </section>
            <section class="table" id="table" style="display: none;">
                <users-table ref="usersTable"/>
            </section>
            <section class="conclusion no-scroll" style="display: none;">
                <h3 class="conclusion-title">Выводы:</h3>
                <ul class="conclusion-cards">
                    <li>
                        <img src="@/assets/images/conclusion/conclusion1.svg" alt="">
                        <p class="conclusion-item-constent">ЭИ-профиль используется для найма новых сотрудников,
                            обладающих
                            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва, а также для
                            планирования траектории развития персонала, основываясь на объективной информации и точных
                            целях.</p>
                    </li>
                    <li>
                        <img src="@/assets/images/conclusion/conclusion2.svg" alt="">
                        <p class="conclusion-item-constent">В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с
                            выделением ключевых параметров эмоционального интеллекта, обеспечивающих эффективность и
                            достижение KPI в рамках профессиональной деятельности. ЭИ-профиль используется для найма
                            новых
                            сотрудников, обладающих.</p>
                    </li>
                    <li>
                        <img src="@/assets/images/conclusion/conclusion3.svg" alt="">
                        <p class="conclusion-item-constent">Обеспечивающих эффективность и достижение KPI в рамках
                            профессиональной деятельности. ЭИ-профиль используется для найма новых сотрудников,
                            обладающих
                            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва, а также для
                            плана.
                        </p>
                    </li>
                </ul>
            </section>

            <router-link class="btn btn-sm btn-show-staffs" to="/analytics/reference">
              <span>Посмотреть подходящих сотрудников</span>
            </router-link>
            <section class="theory no-scroll">
                <collapse-button :title="`Выгоды для компании:`" @click="isOpenTheory = !isOpenTheory"/>
                <Collapse :when="isOpenTheory" class="collapse">
                    <ul class="theory-items" style="display: none;">
                        <li>
                            <p class="theory-item-contant">
                                В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с выделением ключевых
                                параметров
                                эмоционального интеллекта, обеспечивающих эффективность и достижение KPI в рамках
                                профессиональной деятельности.
                                ЭИ-профиль используется для найма новых сотрудников, обладающих необходимыми
                                компетенциями
                                ЭИ, ротации имеющихся, создания кадрового резерва, а также для планирования траектории
                                развития персонала, основываясь на объективной информации и точных целях.
                            </p>
                        </li>
                        <li>
                            <h3>Эталонный профиль:</h3>
                            <p class="theory-item-contant">
                                это статистический анализ эмоционально интеллектуальных профилей сотрудников
                                определенной
                                должности в тандеме с их уровнями достижения KPI. Такой анализ использует объективные
                                данные о способностях идентификации, использования, понимания и управления эмоциями для
                                выявления наиболее важных параметров эмоционального интеллекта, влияющих на
                                эффективность
                                сотрудника на рабочем месте.
                            </p>
                        </li>
                    </ul>
                    <div class="theory-item-bg-green">
                        <div class="theory-item-bg-green-wrapper">
                            <h4>Эталонный профиль позволяет:</h4>
                            <ul class="reference-description">
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion4.svg" alt="">
                                    <p class="item-description">Выявлять управленческий потенциал сотрудников и потенциал продуктивности команд (кто готовый управленческий резерв, в кого целесообразно вкладываться, кому не мешать работать в качестве хорошего специалиста, кто может быть наставником и для кого).</p>
                                    <a class="btn btn-bg-white" style="display: none" href="#table" @click='sort()'>Сравнить</a>
                                </li>
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion5.svg" alt="">
                                    <p class="item-description">Подключить функционал построения индивидуальных и командных траекторий развития по критически важным для повышения продуктивности способностям эмоционального интеллекта на основе выявленных сильных сторон и потенциала для роста.</p>
                                    <a class="btn btn-bg-white" style="display: none" href="#table">Показать всех</a>
                                </li>
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion6.svg" alt="">
                                    <p class="item-description">Выявлять более эффективных кандидатов при подборе на определенную должность, соответствующих уровням эталонного ЭИ-профиля (соответствие эталону, в диапазоне соответствия, несоответствие эталону) нажатием одной кнопки.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Collapse>
            </section>
            <section class="sample">
                <collapse-button :title="`Выборка:`" @click="isOpenSample = !isOpenSample"/>
                <Collapse :when="isOpenSample" class="collapse">
                    <sample-table/>
                </Collapse>
            </section>
            <section class="additional-analysis">
                <collapse-button :title="`Дополнительный анализ:`" @click="isOpenAnalysis = !isOpenAnalysis"/>
                <Collapse :when="isOpenAnalysis" class="collapse">
                    <additional-analysis/>
                </Collapse>
            </section>
            <section class="helpful">
                <div class="helpful-wrapper">
                    <p class="helpful-title">Может быть полезно</p>
                    <ul class="btn-box">
                        <li><a href="#" class="btn  btn-bg-white">Почитать</a></li>
                        <li><a href="#" class="btn  btn-bg-white">Посмотреть</a></li>
                        <li><a href="#" class="btn  btn-bg-white">Заказать</a></li>
                        <li><button @click="showConsultModal" class="btn  btn-bg-white">Получить консультацию</button></li>
                    </ul>
                </div>
                <pop-up-form title="Получить консультацию по продукту"
                             comment="Хочу получить консультацию по Эталонному профилю"
                             v-if="openConsultModal"
                             @closeModal="openConsultModal = false" />
            </section>
        </div>
    </div>
    <div v-else-if="isPreloader" class="table-preloader">
        <preloader/>
    </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {Collapse} from 'vue-collapsed'
import "ag-grid-community/dist/styles/ag-grid.css";
import {useStore} from "vuex";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import {useRoute} from "vue-router";
import {computed} from "@vue/runtime-core";
import ParametersCards from "./ParametersCards/ParametersCards.vue"
import DetailedReportCards from "./DetailedReportCards/DetailedReportCards.vue"
import SampleTable from "./Sample/Sample.vue"
import AdditionalAnalysis from "./AdditionalAnalysis/AdditionalAnalysis.vue"
import cards from "./ParametersCards/parametersCardsList"
import Preloader from "../../components/Technical/Preloader.vue";
import CollapseButton from "./components/CollapseButton/CollapseButton.vue"
import UsersTable from "./UsersTable/UsersTable.vue"
import PopUpForm from "../../components/Popups/PopUpForm.vue";


export default {
    components: {
        PopUpForm,
        Collapse,
        UsersTable,
        CustomCheckbox,
        ParametersCards,
        DetailedReportCards,
        Preloader,
        SampleTable,
        AdditionalAnalysis,
        CollapseButton,
    },
    name: "profile-report",

    setup() {
        const store = useStore(),
            route = useRoute();

        const isOpenDetailed = ref(false),
            isOpenTheory = ref(true),
            isOpenSample = ref(true),
            isOpenAnalysis = ref(true),
            structure = ref(true),
            isPreloader = ref(false),
            usersTable = ref(null),
            openConsultModal = ref(false)

        const detailedBasicItems = [{
            index: 0,
            title: "Общий ЭИ",
            range: "94 — 112",
            structureClass: ["box-1"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Опытный ЭИ",
                range: "94 — 112",
                structureClass: ["box-2"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            },
            {
                index: 2,
                title: "Стратегический ЭИ",
                range: "94 — 112",
                structureClass: ["box-3"],
                description: "Этот концепт элиминирует концепт «нормального», однако лидерство однородно отталкивает материалистический эскапизм. Сознание критично иллюстрирует концептуальный архетип. Индивидуальность, как бы это ни казалось парадоксальным, доступна. Объект противоречиво дает аутотренинг, следовательно тенденция к конформизму связана с менее низким интеллектом. Сознание кумулятивно. Действие отчуждает методологический онтогенез речи, как и предсказывает теория о бесполезном знании."
            }
        ]
        const detailedBranchesItems = [{
            index: 0,
            title: "Распознавание эмоций",
            range: "100 — 108",
            structureClass: ["box-4"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Управление эмоциями",
                range: "92 — 119",
                structureClass: ["box-5"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            }
        ]
        const detailedSubbranchItems = [{
            index: 0,
            title: "Применение эмоций в действиях и мышлении",
            range: "100 — 108",
            structureClass: ["box-6"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Эмпатия",
                range: "92 — 119",
                structureClass: ["box-7"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            },
            {
                index: 2,
                title: "Влияние на эмоции других",
                range: "92 — 119",
                structureClass: ["box-8"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            }
        ]

        const companyName = computed(() => store.state.profile.profile.company.name)
        const referenceReport = computed(() => store.state.analytics.referenceReport)

        if (!referenceReport.value) {
            isPreloader.value = true;
            store.dispatch("analytics/getReferenceReport", route.params.id)
                .then(() => {
                    isPreloader.value = false
                    setCardsInfo()
                })
        } else {
            setCardsInfo()
        }

        function setCardsInfo() {
            Object.keys(referenceReport.value.score).forEach(function (key) {
                const score = referenceReport.value.score[key]
                if (score !== null && score.score_from !== null && score.score_to !== null) {
                    const card = cards.get(key)
                    if (!card) {
                        return
                    }
                    card.min = score.score_from
                    card.max = score.score_to
                    card.important = score.key
                }
            })
        }

        const sort = () => {
            usersTable.value.sortByIdentifyCandidates()
        };

        const showConsultModal = () => {
            openConsultModal.value = !openConsultModal.value
        }

        const print = () => window.print();

        return {
            print,
            isOpenDetailed,
            isOpenTheory,
            isOpenSample,
            isOpenAnalysis,
            structure,
            detailedBasicItems,
            detailedBranchesItems,
            detailedSubbranchItems,
            cards,
            companyName,
            referenceReport,
            isPreloader,
            sort,
            usersTable,
            openConsultModal,
            showConsultModal
        };
    }
};
</script>

<style lang="scss" scoped>

section {
  padding-top: 30px;
}
section > .collapse-btn {
  padding-top: 10px;
  height: 80px;
}

.checkbox {
  padding-bottom: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  @media(max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.references-report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: solid 2px;
  border-bottom-color: #21B579;
}

.bg-color-green {
  position: relative;
  background-color: #2CC185;
  color: #fff;
  text-align: start;
  padding: 61px 40px 49px 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;

  @media (max-width: 900px) {
    padding: 17px 22px 16px;
  }
}

.bg-color-white {
  background-color: #fff;
  text-align: start;
  padding: 38px 40px 49px 40px;

  @media (max-width: 900px) {
    padding: 17px 22px 16px;
  }
}

.btn-bg-white {
  background-color: #fff;
  color: #2CC185;
  margin-right: 8px;

  @media (max-width: 1040px) {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}

.parameters {
  padding-top: 30px;
}

.parameters-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
  padding-bottom: 10px;

  @media(max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.parameters-cards-no-structure{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.parameters-cards {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-areas:
    " . general general . "
    " . arrowMobile arrowMobile . "
    " experienced experienced strategic strategic "
    " arrow arrow arrow2 arrow2 "
    " recognition using understanding management"
    " arrow4 arrow5 arrow6 arrow7 "
    " faces facilitation changes managingYour"
    " images empathy mixing influencing"
    " situations  . dynamics  . ";

  @media (max-width: 1400px) {
    grid-template-areas: " . general general . "
      " . arrowMobile arrowMobile . "
      " experienced experienced strategic strategic "
      " title-experienced title-experienced . . "
      " recognition recognition using using "
      " arrow4 arrow4 arrow5 arrow5 "
      " faces faces facilitation facilitation "
      " images images empathy empathy "
      " situations situations . . "
      " title-strategic title-strategic . . "
      " understanding understanding management management"
      " arrow6 arrow6 arrow7 arrow7 "
      " changes changes managingYour managingYour "
      " mixing mixing influencing influencing "
      " dynamics dynamics . . ";
  }
}

.detailing {
  color: #fff;
  padding-bottom: 5px;

  @media (max-width: 1350px) {
    display: none;
  }
}

.collapse {
  transition: height var(--vc-auto-duration) cubic-bezier(0.3, 0, 0.6, 1);
  overflow: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.collapse::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.detailed-report-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "A ."
    "B C";

  @media (max-width: 900px) {
    grid-template-areas:
      "A"
      "B";
  }
}

.detailed-report-branches-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "D E";

  @media (max-width: 900px) {
    grid-template-areas:
      "D"
      "E";
  }
}

.detailed-report-subbranch-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "F G"
    "H .";

  @media (max-width: 900px) {
    grid-template-areas:
      "F"
      "G"
      "H";
  }
}

.table {
  border-bottom: solid 1px #c9c9c9;
}

.conclusion {
    padding-top: 34px;
    padding-bottom: 43px;
}

.conclusion-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
}

.conclusion-cards {
  padding-top: 20px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(3, 320px);

  @media(max-width: 1400px) {
    grid-template-columns: repeat(3, 250px);
  }

  @media(max-width: 1200px) {
    grid-template-columns: repeat(3, 183px);
  }
}

.conclusion-item-constent {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 1200px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.no-scroll {
    overflow: auto ;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.no-scroll ::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.theory-items {
  padding-top: 20px;
  padding-bottom: 28px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(2, 1fr);

  @media(max-width: 900px) {
    grid-template-columns: 227px;
  }
}

.theory-item-contant {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 900px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.theory-item-bg-green {
  background-color: #2CC185;
  color: #fff;
  border-radius: 10px;
}

.theory-item-bg-green-wrapper {
  padding: 29px 38px 44px;
  overflow-x: scroll;

  @media(max-width: 900px) {
    padding: 17px;
  }
}

.reference-description {
  padding-top: 20px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(3, 1fr);
}

.item-description {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 1023px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.additional-analysis {
  padding-bottom: 20px;
}

.helpful {
  background-color: #2CC185;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.helpful-wrapper {
  padding: 32px 42px 56px;

  @media(max-width: 900px) {
    padding: 13px 19px;
  }
}

.helpful-title {
  color: #fff;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  padding-bottom: 20px;

  @media(max-width: 900px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.btn-box {
  display: flex;
  flex-wrap: wrap;
}

.btn-show-staffs {
  display: block;
  max-width: 620px;
  margin: 10px auto 40px;
  background: #ca84ff;
  color: #fff;
  font-size: 18px;
  font-weight: 1000;
  padding: 20px 40px;
}

@media (max-width: 900px) {
  .no-mobile {
    display: none;
  }
}
</style>