export default {
    value: [
        {
            field: "id",
            hide: true,
        },
        {
            field: "group",
            hide: true,
        },
        {
            headerName: "ФИО",
            field: "name",

        },
        {
            headerName: "Общий ЭИ",
            field: "total",
        },
        {
            headerName: "Опытный ЭИ",
            field: "domain_1",
        },
        {
            headerName: "Стратегический ЭИ",
            field: "domain_2",
        },
        {
            headerName: "Использование эмоций для решения задач",
            field: "branch_2",
        },
        {
            headerName: "Анализ эмоций",
            field: "branch_3",
        },
        {
            headerName: "Применение эмоций в действиях и мышлении",
            field: "section_2",
        },
        {
            headerName: "Эмпатия",
            field: "section_6",
        },
        {
            headerName: "Понимание соединения эмоций в чувства",
            field: "section_7",
        },
        {
            headerName: "KPI",
            field: "kpi",
        },
    ],
};